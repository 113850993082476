import React, { useLayoutEffect } from 'react'
import { connect } from 'redux-zero/react'
import actions from '../../store/actions'
import './styles/fullScreenModalStyle.css'
import BacKIcon from '../../assets/svg-icons/chevron-right.svg'
import PropTypes from 'prop-types'
import styled from 'styled-components'
const FullModal = styled.div`
  position: absolute;
  left: 0;
  width: 100%;
  background: rgba(0, 0, 0, 0.6);
  display: block;
  overflow-y: auto;
  min-height: 1200px;
  transition: all 400ms ease-in-out;
  top: ${props => (props.isOpen ? '0' : '100%')};
  opacity: ${props => (props.isOpen ? '1' : '0')};
  visibility: ${props => (props.isOpen ? 'visible' : 'hidden')};
  height: ${props => (props.isOpen ? '100%' : '0')};}
`

const FullScreenModal = props => {
  useLayoutEffect(() => {
    // Get original body overflow
    const originalStyle = window.getComputedStyle(document.body).overflow
    // Prevent scrolling on mount
    document.body.style.overflow = props.isOpen ? 'hidden' : 'auto'
    // Re-enable scrolling when component unmounts
    return () => {
      document.body.style.overflow = originalStyle
    }
  }, [])
  const showHideClassName = props.isOpen
    ? 'full-modal full-modal-display-block'
    : 'full-modal full-modal-display-none'
  const closeBtn = (
    <button
      style={{
        background: 'transparent',
        border: 'none',
        cursor: 'pointer',
      }}
      onClick={props.close}
    >
      <img src={BacKIcon} style={{ maxWidth: '30%' }} />
    </button>
  )

  const header = <h2>{props.header}</h2>

  return (
    <FullModal isOpen={props.isOpen}>
      <section className="full-modal-main">
        <div className={'full-modal-header'}>
          {header}
          {closeBtn}
        </div>
        <div className={'full-modal-content'}>{props.children}</div>
      </section>
    </FullModal>
  )
}

FullScreenModal.defaultProps = {
  children: null,
  isOpen: false,
}

FullScreenModal.propTypes = {
  children: PropTypes.any,
  isOpen: PropTypes.bool,
  close: PropTypes.func,
}

const mapStateToProps = ({
  selectedUser,
  showUserModal,
  showDeleteUserWarningModal,
  editUser,
}) => ({
  selectedUser,
  showUserModal,
  showDeleteUserWarningModal,
  editUser,
})
export default connect(
  mapStateToProps,
  actions
)(FullScreenModal)
