import React, { useState, useEffect } from 'react'
import { PropTypes } from 'prop-types'
import './styles/formStyle.css'
import TextField from '@material-ui/core/TextField'
import Autocomplete from '@material-ui/lab/Autocomplete'
import { connect } from 'redux-zero/react/index'
import actions from '../../store/actions'
import { isSolid } from '../../utils/taskHandler'
import { isNil } from 'lodash'

const SingleGroupSelector = props => {
  const [options, setOptions] = useState([])

  const getGroupsOptions = groups => {
    try {
      let opt = []
      Object.keys(groups).forEach((key, index) => {
        const getTitle = groups_id => {
          if (!isSolid(groups[groups_id]['parent'])) {
            return groups[groups_id]['name']
          }
          return `${getTitle(groups[groups_id]['parent'])}, ${
            groups[groups_id]['name']
          }`
        }

        opt.push({
          id: index,
          key: index,
          title: groups[key]['name'],
          value: key,
        })
      })

      return opt
    } catch (e) {
      return [
        {
          id: -1,
          key: -1,
          title: 'no stores',
          value: -1,
        },
      ]
    }
  }

  useEffect(() => {
    if (isSolid(props.groups)) {
      setOptions(getGroupsOptions(props.groups))
    }
  }, [props.groups])

  const get_value = () => {
    let selected = null
    options.forEach(option => {
      if (option.value === props.value) {
        selected = option
      }
    })
    return selected
  }
  const handleGroupsSelect = (event, val) => {
    let groupId = null
    if (!isNil(val)) {
      groupId = val.value
    }
    props.hadleClearUserID()
    props.setSelectedGroup(props.groups[groupId])
    props.handleTaskFetcherStoreSelect(groupId)
    props.setPosts([])
  }
  return (
    <Autocomplete
      id="combo-box-demo"
      options={options}
      style={{ width: 300, marginTop: '20px' }}
      getOptionLabel={option => option.title}
      onChange={handleGroupsSelect}
      value={get_value()}
      renderInput={params => (
        <TextField {...params} label={props.label} fullWidth />
      )}
    />
  )
}

SingleGroupSelector.defaultProps = {
  required: false,
  multiple: false,
}

SingleGroupSelector.propTypes = {
  name: PropTypes.string,
  label: PropTypes.string,
  onChange: PropTypes.func,
}

const mapStateToProps = ({
  selected_rid,
  enableDateSubmit,
  groups,
  selectedGroup,
}) => ({
  selected_rid,
  enableDateSubmit,
  groups,
  selectedGroup,
})
export default connect(mapStateToProps, actions)(SingleGroupSelector)
