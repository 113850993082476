import React from 'react'
import styled from 'styled-components'
import { connect } from 'redux-zero/react/index'
import actions from '../../store/actions'
import Keys from '../../utils/Keys'
import Button from './Buttons/Button'
import { get } from 'lodash'
import Select from './SingleGroupSelector'
import { getNow, getReportLimit } from '../../utils/dateHandler'
import './styles/taskFetcherStyle.css'
import { getReports } from '../../services/api'

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: 40px;
`

const ReportFetcher = props => {
  const { selectedGroup, selected_rid, retailConfig } = props
  const handleResponse = response => {
    if (response.status === 200) {
      let reports = response.reports
      reports = reports.filter(report => report !== null)
      if (reports.length === 0) {
        props.handleAlert({
          text: 'No reports were fetched',
          type: 'info',
        })
      }
      props.setReports([...reports])
    }
  }

  const _getReports = () => {
    const now = getNow('YYYYMMDD')
    const limit = getReportLimit(
      retailConfig[selected_rid][Keys.REPORTS_FETCH_POLICY],
      retailConfig[selected_rid][Keys.REPORTS_FETCH_BY_RANGE_VALUE]
    )

    const params = {
      rid: selected_rid,
      group: selectedGroup[Keys.OBJECT_ID],
      date: now,
      limit: limit,
    }

    props.showFullScreenLoader()
    getReports(handleResponse, params, props.hideFullScreenLoader)
  }

  return (
    <Wrapper>
      <Select
        label="Stores"
        name="stores"
        className="tasksFetcherSelect"
        value={
          get(props.selectedGroup, Keys.OBJECT_ID, false)
            ? props.selectedGroup[Keys.OBJECT_ID]
            : null
        }
      />

      <Button
        style={{ marginBottom: '0' }}
        onClick={_getReports}
        label={'Fetch Reports'}
      />
    </Wrapper>
  )
}

const mapStateToProps = ({
  selectedGroup,
  selected_rid,
  enableDateSubmit,
  userRidsDetails,
  userAreas,
}) => ({
  selectedGroup,
  selected_rid,
  enableDateSubmit,
  userRidsDetails,
  userAreas,
})
export default connect(
  mapStateToProps,
  actions
)(ReportFetcher)
