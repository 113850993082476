import React, { Component } from 'react'
import styled from 'styled-components'
import { connect } from 'redux-zero/react'
import actions from '../../store/actions'
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css'
import BootstrapTable from 'react-bootstrap-table-next'
import Colors from '../../utils/Colors'
import Keys from '../../utils/Keys'
import './style.css'
import isNil from 'lodash/isNil'
import TextAddButton from '../Elements/Buttons/TextAddButton'
import Switch from '../Elements/FormElements/Switch'

const TableWrapper = styled.div``
const NewReportTable = props => {
  const _active = cell => {
    return <Switch />
  }

  const tableColor = {}

  const headerStyle = {
    textAlign: 'center',
    fontWeight: 'bold',
  }
  const columns = [
    {
      dataField: Keys.REFERENCE_ID,
      text: 'Reference ID',
      sort: true,
      headerStyle: headerStyle,
    },
    {
      dataField: Keys.TITLE,
      text: 'Title',
      headerStyle: headerStyle,
    },
    {
      dataField: Keys.RS_ID,
      text: 'retail-store ID',
      sort: true,
      headerStyle: headerStyle,
    },
    {
      dataField: Keys.DATE,
      text: 'Date',
      sort: true,
      headerStyle: headerStyle,
    },

    {
      dataField: Keys.UPDATE_AT_TS,
      text: 'Updated at',
      headerStyle: headerStyle,
    },
  ]

  return (
    <TableWrapper className="itemTableWrapper" tableColor={tableColor}>
      <BootstrapTable
        keyField={Keys.DATE}
        data={props.reports}
        columns={columns}
        bordered={false}
        rowStyle={{
          backgroundColor: tableColor.color,
        }}
      />
    </TableWrapper>
  )
}

const mapStateToProps = ({ reports }) => ({ reports })

export default connect(
  mapStateToProps,
  actions
)(NewReportTable)
