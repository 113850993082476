import React from 'react'
import { PropTypes } from 'prop-types'
import '../styles/formStyle.css'
import Colors from '../../../utils/Colors'
import { withStyles } from '@material-ui/core/styles'
import Switch from '@material-ui/core/Switch'
import InputLabel from '@material-ui/core/InputLabel'

import styled from 'styled-components'
const styles = theme => ({
  colorSwitchBase: {
    color: Colors.DISABLE,
    '&$colorChecked': {
      color: Colors.ACTION,
      '& + $colorBar': {
        backgroundColor: Colors.ACTION,
      },
    },
  },
  colorBar: {},
  colorChecked: {},
})

const Col = styled.div`
  margin-top: 16px;
  display: flex;
  flex-direction: column;
`

const FormSwitch = props => {
  return (
    <li className="formListItem">
      <Col>
        <InputLabel shrink>{props.label}</InputLabel>
        <Switch
          value={props.value}
          checked={props.checked}
          onChange={props.onChange}
          color="primary"
          classes={{
            switchBase: props.classes.colorSwitchBase,
            checked: props.classes.colorChecked,
          }}
        />
      </Col>
    </li>
  )
}

FormSwitch.defaultProps = {
  required: false,
  checked: true,
  label: '',
}

FormSwitch.propTypes = {
  label: PropTypes.string,
  value: PropTypes.string,
  onChange: PropTypes.func,
}

export default withStyles(styles)(FormSwitch)
