import React from 'react'
import Button from '@material-ui/core/Button'
import { makeStyles, withStyles } from '@material-ui/core/styles'
import Add from '@material-ui/icons/AddRounded'
import { green, purple } from '@material-ui/core/colors'
import PropTypes from 'prop-types'

const useStyles = makeStyles(theme => ({
  button: {
    margin: theme.spacing(1),
  },
}))

const PurpleButton = withStyles(theme => ({
  root: {
    color: purple[800],
    '&:hover': {
      backgroundColor: purple[100],
    },
  },
}))(Button)

const GreenButton = withStyles(theme => ({
  root: {
    color: green[800],
    '&:hover': {
      backgroundColor: green[100],
    },
  },
}))(Button)

const TextAddButton = props => {
  const classes = useStyles()

  return props.color === 'purple' ? (
    <PurpleButton
      variant="outlined"
      className={classes.button}
      onClick={props.onClick}
      endIcon={<Add />}
    >
      {props.label}
    </PurpleButton>
  ) : props.color === 'green' ? (
    <GreenButton
      variant="outlined"
      className={classes.button}
      onClick={props.onClick}
      endIcon={<Add />}
    >
      {props.label}
    </GreenButton>
  ) : (
    <Button
      variant="outlined"
      color={props.color}
      className={classes.button}
      onClick={props.onClick}
      endIcon={<Add />}
    >
      {props.label}
    </Button>
  )
}

TextAddButton.defaultProps = {
  color: 'primary',
}

TextAddButton.propTypes = {
  color: PropTypes.string,
  onClick: PropTypes.func,
}

export default TextAddButton
