import React from 'react'
import Layout from '../components/layout'
import UserModal from '../components/Elements/UserModal'
import styled from 'styled-components'
import 'bootstrap/dist/css/bootstrap.css'
import FullScreenLoader from '../components/FullScreenLoader'
import ReportsFetcher from '../components/Elements/ReportsFetcher'
import NewReportTable from '../components/Table/NewReportTable'
import withRetailConfig from '../components/Elements/withRetailConfig'

// @ts-ignore
const HeaderStyled = styled.div`
  display: flex;
  align-items: center;
  width: 20%;
  margin: 10px;
`

const ReportPage = props => {
  return (
    <React.Fragment>
      <Layout>
        <HeaderStyled>
          <h1>Reports</h1>
        </HeaderStyled>
        <br />
        <br />
        <ReportsFetcher retailConfig={props.retailsConfig} />
        <FullScreenLoader loading={props.loading} />

        <NewReportTable />
      </Layout>
      <UserModal />
    </React.Fragment>
  )
}

export default withRetailConfig(ReportPage)
